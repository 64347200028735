import { DecodedJwt, decodeJwt } from 'src/shared/utils/models/decoded-jwt';

export function decodeToken(token: string): string[] {
  const accessToken: DecodedJwt = decodeJwt(token);
  const azp = accessToken['azp'];
  const userRole: string[] = accessToken.resource_access[azp!].roles;
  return userRole;
}

export function getSub(token: string) {
  const accessToken: DecodedJwt = decodeJwt(token);
  return accessToken.sub;
}

export function isTokneValid(token: string) {
  const accessToken: DecodedJwt = decodeJwt(token);
  if (Date.now() >= accessToken.exp! * 1000) return false;
  return true;
}
