import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AccessTokenResponse } from 'src/shared/utils/models/access-token-response';
import { VerificationCode } from 'src/shared/utils/models/verification-code';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private http: HttpClient, private snackBar: MatSnackBar) {}

  public signIn(credentials: Credential): Observable<AccessTokenResponse> {
    return this.http.post<AccessTokenResponse>('login', credentials).pipe(
      take(1),
      catchError((e) => {
        this.snackBar.open('Please check your credentials !', null!, {
          duration: 3000,
        });
        return throwError(e);
      })
    );
  }

  public sendVerificationCode(
    verificationCode: VerificationCode
  ): Observable<Credential> {
    let params = new HttpParams();
    params = params.append('code', verificationCode.code);
    params = params.append('id', verificationCode.id);
    return this.http.get<Credential>('verify', { params }).pipe(
      take(1),
      catchError((e) => {
        this.snackBar.open('Email Verification faild !', 'ok');
        return throwError(e);
      })
    );
  }
}
