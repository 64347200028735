import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import {
  ADMIN_NAV,
  COUPLE_NAV,
  SIGN_IN_NAV,
  SIGN_UP_NAV,
} from 'src/shared/utils/menu-items';

const routes: Routes = [
  {
    path: '',
    // component:SiteComponent
    loadChildren: () => import('./site/site.module').then((m) => m.SiteModule),
  },
  {
    path: SIGN_IN_NAV,
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: SIGN_UP_NAV,
    loadChildren: () =>
      import('./login/login-sign-up/login-sign-up.module').then(
        (m) => m.LoginSignUpModule
      ),
  },
  {
    path: ADMIN_NAV,
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: COUPLE_NAV,
    loadChildren: () =>
      import('./couple/couple.module').then((m) => m.CoupleModule),
  },
  {
    path: '404',
    loadChildren: () =>
      import('./site/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      preloadingStrategy: NoPreloading,
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
