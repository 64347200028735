/***
 * @author Randika Hapugoda
 */
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Computed,
  DataAction,
  Payload,
  Persistence,
  StateRepository,
} from '@ngxs-labs/data/decorators';
import { NgxsDataRepository } from '@ngxs-labs/data/repositories';
import { State, Store } from '@ngxs/store';
import { StateClear } from 'ngxs-reset-plugin';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { decodeToken, getSub } from '../services/auth/auth.utils';
import { AccessTokenResponse } from '../utils/models/access-token-response';
import { UserType } from '../utils/models/user-type';
import { VerificationCode } from '../utils/models/verification-code';

interface AuthStateModel {
  accessTokenResponse?: AccessTokenResponse;
  userRole?: string[];
  userType?: UserType;
  /** User id if provided by oauth2 token */
  sub?: string;
}

@Persistence()
@StateRepository()
@State<AuthStateModel>({
  name: 'auth',
  defaults: { userType: UserType.VISITOR },
})
@Injectable({ providedIn: 'root' })
export class AuthState extends NgxsDataRepository<AuthStateModel> {
  constructor(
    private authService: AuthService,
    private store: Store,
    private router: Router
  ) {
    super();
  }

  @Computed() get userRole(): Observable<string[]> {
    return this.state$.pipe(map((s) => s?.userRole!));
  }

  @Computed() get sub(): Observable<string> {
    return this.state$.pipe(map((s) => s?.sub!));
  }

  @Computed() get isCouple(): boolean {
    return this.getState().userType == UserType.COUPLE;
  }

  @Computed() get userType(): Observable<UserType> {
    return this.state$.pipe(map((s) => s?.userType!));
  }

  @Computed() public get accessToken(): Observable<string> {
    return this.state$.pipe(map((s) => s?.accessTokenResponse?.access_token!));
  }

  @DataAction({ insideZone: true }) login(
    @Payload('credential') credential: Credential
  ) {
    return this.startAuthForCredentials(credential);
  }

  @DataAction() logout() {
    this.store.dispatch(new StateClear());
    this.patchState({ userType: UserType.VISITOR });
    this.router.navigate(['/']);
  }

  @DataAction() sendVerifyEmail(
    @Payload('verificationCode') verificationCode: VerificationCode
  ) {
    return this.authService
      .sendVerificationCode(verificationCode)
      .pipe(mergeMap((c) => this.startAuthForCredentials(c)));
  }

  @DataAction() setAccessTokenResponse(
    @Payload('accessTokenResponse') accessTokenResponse: AccessTokenResponse
  ) {
    const sub = getSub(accessTokenResponse.access_token!);
    return this.ctx.patchState({ accessTokenResponse, sub });
  }

  @DataAction() setUserRoles(@Payload('userRole') userRole: string[]) {
    return this.ctx.patchState({ userRole });
  }

  @DataAction() updateUserType(@Payload('userType') userType: UserType) {
    return this.ctx.patchState({ userType });
  }

  private startAuthForCredentials(crerential: Credential) {
    return this.authService.signIn(crerential).pipe(
      map((accessTokenResponse: AccessTokenResponse) => {
        let userRole: string[] = decodeToken(accessTokenResponse.access_token!);
        this.patchState({ userRole, accessTokenResponse });
      })
    );
  }
} // class
