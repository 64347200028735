/***
 * @author Randika Hapugoda
 */
import { isPlatformBrowser } from '@angular/common';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthState } from '../state/auth.state';

import { DecodedJwt, decodeJwt } from '../utils/models/decoded-jwt';

@Injectable({ providedIn: 'root' })
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authState: AuthState,
    @Inject(PLATFORM_ID) private platformId: string
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!isPlatformBrowser(this.platformId)) return next.handle(req);

    // Iggnore assets
    if (req.url.startsWith('assets')) return next.handle(req);
    if (req.url.startsWith('login')) return next.handle(req);

    // append token
    return this.authState.accessToken.pipe(
      mergeMap((t) => {
        if (t && this.isTokneValid(t)) {
          let headers = req.headers.append('Authorization', 'Bearer ' + t);
          const tokenizedRequest = req.clone({ headers });
          return next.handle(tokenizedRequest);
        }
        return next.handle(req);
      })
    );
  } // intercept()

  public isTokneValid(token: string) {
    const accessToken: DecodedJwt = decodeJwt(token);
    if (Date.now() >= accessToken.exp! * 1000) return false;
    return true;
  }
}
