import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MetaTagService } from 'src/shared/services/api/meta-tag/meta-tag.service';
import { LinkService } from 'src/shared/services/canonical/canonical.service';
import { AppState } from 'src/shared/state/app.state';

declare let gtag: Function;
declare let fbq: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'hawai-angular';
  constructor(
    public appState: AppState,
    public router: Router,
    public linkService: LinkService,
    private metaTagService: MetaTagService
  ) {
    this.appState.showLoading(false);

    if (environment.production) {
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          map((e) => <NavigationEnd>e)
        )
        .subscribe((event: NavigationEnd) => {
          this.metaTagService
            .updateMetaTags(event.url)
            .pipe(take(1))
            .subscribe();

          gtag('config', 'UA-149968469-1', {
            page_path: event.urlAfterRedirects,
          });
          fbq('track', 'PageView');
        });
    }
  } // construcor

  ngOnInit() {
    this.linkService.startRouteListener();
    // this.canonicalService.setCanonicalURL();
  }
}
