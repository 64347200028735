import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { HateosPagedResponse } from '@extendz/api';
import { Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { MetaTag } from './meta-tag';
import { Title } from '@angular/platform-browser';

export const METATAGS_URL = 'metaTags';
@Injectable({
  providedIn: 'root',
})
export class MetaTagService {
  constructor(
    private http: HttpClient,
    private meta: Meta,
    private title: Title
  ) {}

  public getByPath(path: string): Observable<MetaTag> {
    let params = new HttpParams();
    params = params.append('path', path);
    return this.http.get(METATAGS_URL, { params }).pipe(
      take(1),
      map((d: HateosPagedResponse) => d._embedded![METATAGS_URL][0])
    );
  }

  public updateMetaTags(path: string) {
    return this.getByPath(path).pipe(
      filter((m) => m != undefined),
      tap((m) => {
        this.title.setTitle(m.title!);

        // general
        this.meta.updateTag({ name: 'title', content: m.title! });
        this.meta.updateTag({ name: 'description', content: m.description! });
        // facebook
        this.meta.updateTag({ name: 'og:type', content: 'website' });
        this.meta.updateTag({ name: 'og:title', content: m.title! });
        this.meta.updateTag({
          name: 'og:description',
          content: m.description!,
        });
        this.meta.updateTag({ name: 'og:image', content: m.image! });
        this.meta.updateTag({ name: 'og:url', content: m.url! });

        // twitter
        this.meta.updateTag({
          name: 'twitter:card',
          content: 'summary_large_image',
        });
        this.meta.updateTag({ name: 'twitter:url', content: m.url! });
        this.meta.updateTag({ name: 'twitter:title', content: m.title! });
        this.meta.updateTag({
          name: 'twitter:description',
          content: m.description!,
        });
        this.meta.updateTag({ name: 'twitter:image', content: m.image! });
      })
    );
  }
}
