<router-outlet></router-outlet>
<div class="loading-container" *ngIf="appState.loading | async">
    <div class="loader">
      <div class="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
  