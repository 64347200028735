import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SIGN_IN_MENU_ITEM } from 'src/shared/utils/menu-items';

@Injectable({ providedIn: 'root' })
export class TokenExpiredInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse) => {
        if (e.status === 401)
          if (!this.router.url.startsWith(`/${SIGN_IN_MENU_ITEM.url}`)) {
            // log out
          }
        return throwError(e);
      })
    );
  } // intercept()
}
