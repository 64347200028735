export enum UserType {
  // User who found a couple
  GUEST = 'GUEST',
  // User who is a couple
  COUPLE = 'COUPLE',
  // User who want to buy form Gifting Ex: E-Gift cards,cakes etc.
  GIFTER = 'GIFTER',
  // User who want to redeem using ONLY E-Gift cards
  REDEEMER = 'REDEEMER',
  // User who came to just window show.Can't add anything to cart. All others can
  VISITOR = 'VISITOR',
}
