import { Injectable } from '@angular/core';
import {
  Computed,
  DataAction,
  Payload,
  StateRepository
} from '@ngxs-labs/data/decorators';
import { NgxsDataRepository } from '@ngxs-labs/data/repositories';
import { State } from '@ngxs/store';
import { map } from 'rxjs/operators';

export class AppStateModel {
  loading?: boolean;
}

@StateRepository()
@State<AppStateModel>({
  name: 'app',
  defaults: {
    loading: false,
  },
})
@Injectable({ providedIn: 'root' })
export class AppState extends NgxsDataRepository<AppStateModel> {

  @Computed() get loading() {
    return this.state$.pipe(map((s) => s?.loading));
  }

  @DataAction() showLoading(@Payload('loading') loading: boolean) {
    return this.ctx.patchState({ loading });
  }
} // class
